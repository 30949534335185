<template>
    <div class="stepsbox dp-f jc-c">
        <div v-for="(el,ind) in state.datas" :key="ind" class="dp-f ai-c" :class="props.index>=ind?'stepitemac':''">
            <div class="dp-f ai-c cu-p" @click="setStep(ind)">
                <div class="subscript dp-fc pb-1 mr-6">{{ ind+1 }}</div>
                <div>{{ el.title }}</div>
            </div>
            <template v-if="ind != state.datas.length-1">
                <img src="@/assets/img/realname/moreac.png" class="w-6 h-10 mt-2 ml-26 mr-20" v-if="props.index>ind">
                <img src="@/assets/img/realname/more.png" class="w-6 h-10 mt-2 ml-26 mr-20" v-else>
            </template>
        </div>
    </div>
</template>
<script setup>
import {router_push_name} from "@/utils/server/router";
import { reactive,defineEmits } from "vue";
const props = defineProps({
  index: {
    //当前步骤下标
    type: Number,
    default:()=>{
        return 1
    }
  },
});
const emit = defineEmits(['setStep'])
const state = reactive({
    datas:[
        {title:'同意协议'},
        {title:'填写身份信息及验证'},
        {title:'完成认证'}
    ]
});
// 重新设置进度条
const setStep=(ind)=>{
    // 同意协议无需返回
    if(ind==0)
        return
    // 无法直接跳转当前步骤或之后步骤
    if(props.index<=ind)
        return
    emit('setStep',ind)
}
</script>
<style lang="scss" scoped >
.stepsbox{
    font-size: 14px;
    font-family: PingFangSC-Regular, PingFang SC;
    font-weight: 400;
    color: #666666;
    .subscript{
        width: 22px;
        height: 22px;
        border: 1px solid #E1E1E1;
        border-radius: 50%;
    }
    .arrow{
        color: rgba(153, 153, 153, 1);
    }
}
.stepitemac{
    .subscript{
        background: #276FF9;
        border: 1px solid #276FF9;
        color: #fff;
    }
}
</style>